import React from "react"
import * as containerStyles from "./retailers.module.css"

export default function Retfees() {
  return (
  <div class={containerStyles.c16}> 
    <p class={containerStyles.fee_header}><b>Spar Group:</b></p>
        <ul class={containerStyles.ul_fees}>
            <li>Transaction fee (exd. Vat): R7.7</li>
            <li>Cash Handling Fee: 0.5%</li>
            <li>Debit Card Fee: 0.85%</li>
            <li>Credit Card Fee: 2.5%</li>
        </ul><p class={containerStyles.fee_header}><b>Pick n Pay (Pty) Ltd:</b></p>
        <ul class={containerStyles.ul_fees}>
            <li>Transaction fee (exd. Vat): R7.7</li>
            <li>Cash Handling Fee: 0.5%</li>
            <li>Debit Card Fee: 0.68%</li>
            <li>Credit Card Fee: 1.72%</li>
        </ul><p class={containerStyles.fee_header}><b>Flash Traders:</b></p>
        <ul class={containerStyles.ul_fees}>
            <li>Transaction fee (exd. Vat): R7.7</li>
            <li>Cash Handling Fee: 0.7%</li>
            <li>Debit Card Fee: 1.5%</li>
            <li>Credit Card Fee: 1.5%</li>
        </ul><p class={containerStyles.fee_header}><b>TopItUp:</b></p>
        <ul class={containerStyles.ul_fees}>
            <li>Transaction fee (exd. Vat): R7.7</li>
            <li>Cash Handling Fee: 0.68%</li>
            <li>Debit Card Fee: 2.65%</li>
            <li>Credit Card Fee: 2.65%</li>
        </ul><p class={containerStyles.fee_header}><b>Masterpass:</b></p>
        <ul class={containerStyles.ul_fees}>
            <li>Transaction fee (exd. Vat): R2.03</li>
            <li>Cash Handling Fee: 0%</li>
            <li>Debit Card Fee: 1.95%</li>
            <li>Credit Card Fee: 2.60%</li>
        </ul><p class={containerStyles.fee_header}><b>Spar Direct:</b></p>
        <ul class={containerStyles.ul_fees}>
            <li>Transaction fee (exd. Vat): R7.7</li>
            <li>Cash Handling Fee: 0.7%</li>
            <li>Debit Card Fee: 1.5%</li>
            <li>Credit Card Fee: 1.5%</li>
        </ul><p class={containerStyles.fee_header}><b>Snapscan:</b></p>
        <ul class={containerStyles.ul_fees}>
            <li>Transaction fee (exd. Vat): R2.03</li>
            <li>Cash Handling Fee: 1.5%</li>
            <li>Debit Card Fee: 1.95%</li>
            <li>Credit Card Fee: 2.6%</li>
        </ul><p class={containerStyles.fee_header}><b>Massmart:</b></p>
        <ul class={containerStyles.ul_fees}>
            <li>Transaction fee (exd. Vat): R7.7</li>
            <li>Cash Handling Fee: 0.35%</li>
            <li>Debit Card Fee: 0.75%</li>
            <li>Credit Card Fee: 2.25%</li>
        </ul><p class={containerStyles.fee_header}><b>EFT:</b></p>
        <ul class={containerStyles.ul_fees}>
            <li>Transaction fee (exd. Vat): R2.03</li>
            <li>Cash Handling Fee: 1.5%</li>
            <li>Debit Card Fee: 0%</li>
            <li>Credit Card Fee: 0%</li>
        </ul><p class={containerStyles.fee_header}><b>Zapper:</b></p>
        <ul class={containerStyles.ul_fees}>
            <li>Transaction fee (exd. Vat): R2.03</li>
            <li>Cash Handling Fee: 1.5%</li>
            <li>Debit Card Fee: 1.95%</li>
            <li>Credit Card Fee: 2.6%</li>
        </ul><p class={containerStyles.fee_header}><b>JD Group:</b></p>
        <ul class={containerStyles.ul_fees}>
            <li>Transaction fee (exd. Vat): R7.7</li>
            <li>Cash Handling Fee: 0.7%</li>
            <li>Debit Card Fee: 1.5%</li>
            <li>Credit Card Fee: 1.5%</li>
        </ul><p class={containerStyles.fee_header}><b>MTN Mobile Money:</b></p>
        <ul class={containerStyles.ul_fees}>
            <li>Transaction fee (exd. Vat): R7.7</li>
            <li>Cash Handling Fee: 1.5%</li>
            <li>Debit Card Fee: 0%</li>
            <li>Credit Card Fee: 0%</li>
        </ul><p class={containerStyles.fee_header}><b>Kazang:</b></p>
        <ul class={containerStyles.ul_fees}>
            <li>Transaction fee (exd. Vat): R7.7</li>
            <li>Cash Handling Fee: 0.87%</li>
            <li>Debit Card Fee: 2.50%</li>
            <li>Credit Card Fee: 2.50%</li>
        </ul><p class={containerStyles.fee_header}><b>MyVodacom:</b></p>
        <ul class={containerStyles.ul_fees}>
            <li>Transaction fee (exd. Vat): R7.7</li>
            <li>Cash Handling Fee: 1.5%</li>
            <li>Debit Card Fee: 0.9%</li>
            <li>Credit Card Fee: 2.1%</li>
        </ul><p class={containerStyles.fee_header}><b>Spar Direct Botswana:</b></p>
        <ul class={containerStyles.ul_fees}>
            <li>Transaction fee (exd. Vat): R7.7</li>
            <li>Cash Handling Fee: 0.7%</li>
            <li>Debit Card Fee: 1.5%</li>
            <li>Credit Card Fee: 1.5%</li>
        </ul><p class={containerStyles.fee_header}><b>Capitec Billpay:</b></p>
        <ul class={containerStyles.ul_fees}>
            <li>Transaction fee (exd. Vat): R4.16</li>
            <li>Cash Handling Fee: 1%</li>
            <li>Debit Card Fee: 0%</li>
            <li>Credit Card Fee: 0%</li>
        </ul><p class={containerStyles.fee_header}><b>Telkom Pay:</b></p>
        <ul class={containerStyles.ul_fees}>
            <li>Transaction fee (exd. Vat): R2.03</li>
            <li>Cash Handling Fee: 1.5%</li>
            <li>Debit Card Fee: 0%</li>
            <li>Credit Card Fee: 0%</li>
        </ul><p class={containerStyles.fee_header}><b>FNB Digital Banking:</b></p>
        <ul class={containerStyles.ul_fees}>
            <li>Transaction fee (exd. Vat): R4.16</li>
            <li>Cash Handling Fee: 1%</li>
            <li>Debit Card Fee: 1%</li>
            <li>Credit Card Fee: 2%</li>
        </ul>
  </div>
  )
}